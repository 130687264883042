import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { BaggingSession, ListBaggingSessionQuery, ListBaggingSessionQueryVariables } from '../libs';
import { LIST_BAGGING_SESSION_QUERY } from '../libs/graphQL';
import { MealViewFilters } from '../views/LeftoverMetrics/MealsView/MealsView';

const useListBaggingSessionQuery = (filters: MealViewFilters) => {
  const [next, setNext] = useState<string | undefined>();
  const [baggingSessions, setBaggingSessions] = useState<BaggingSession[]>();

  const { fetchMore, loading } = useQuery<ListBaggingSessionQuery, ListBaggingSessionQueryVariables>(LIST_BAGGING_SESSION_QUERY, {
    variables: {
      kitchen: filters.kitchen,
      startDate: filters.startDate,
      endDate: filters.endDate,
      shift: filters.shift,
      actions: filters.actions,
      limit: 5
    },
    onCompleted(data) {
      setNext(data.listBaggingSession?.next);
      setBaggingSessions(data.listBaggingSession?.sessions ?? []);
    },
    onError(error) {
      toast.error(error.message);
    }
  });

  function onFetchMore() {
    if (next) {
      fetchMore({
        variables: { next },
        updateQuery(previousQueryResult, { fetchMoreResult }) {
          const prevBaggingSessionsData = previousQueryResult.listBaggingSession;
          const baggingSessionsData = fetchMoreResult.listBaggingSession;

          return {
            listBaggingSession: {
              ...previousQueryResult.listBaggingSession,
              next: fetchMoreResult.listBaggingSession?.next,
              sessions: [...(prevBaggingSessionsData?.sessions ?? []), ...(baggingSessionsData?.sessions ?? [])]
            }
          };
        }
      });
    }
  }

  return {
    hasNext: Boolean(next),
    baggingSessions,
    sessionsLoading: loading,
    fetchMoreSessions: onFetchMore
  };
};

export default useListBaggingSessionQuery;
