import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { round } from 'lodash-es';
import { FoodComponent } from '../../../../libs';
import { getCookedRawFactor, weightCalculator } from '../../../../libs/utils/foodComponentHelper';

interface SectionTableRowProps {
	foodComponent: FoodComponent;
}

const SectionTableRow = ({ foodComponent }: SectionTableRowProps) => {
	const theme = useTheme();
	const childWeight = foodComponent.child?.weight || 0;
	const cookedRawFactor = getCookedRawFactor(foodComponent);

	return (
		<TableRow hover>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', fontWeight: 600 }}>{foodComponent.name.en}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>
				{round(
					weightCalculator(
						foodComponent,
						foodComponent.weight ?? 0,
						foodComponent.count ?? 0,
						foodComponent.cupsQuantity,
						foodComponent.quantities || [],
						cookedRawFactor
					) +
					childWeight * cookedRawFactor,
					2
				)}{' '}
				g
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>
				{foodComponent.kitchenWastageInputs?.preppedWeight ? foodComponent.kitchenWastageInputs?.preppedWeight + ' g' : ''}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>
				{round(
					weightCalculator(
						foodComponent,
						foodComponent.cookedWeight ?? 0,
						foodComponent.count ?? 0,
						foodComponent.cupsQuantity,
						foodComponent.quantities || []
					) + childWeight,
					2
				)}{' '}
				g
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>
				{foodComponent.kitchenWastageInputs?.chilledWeight ? foodComponent.kitchenWastageInputs?.chilledWeight + ' g' : ''}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '16px', fontWeight: 600, color: theme.palette.neutral600 }}>
				{foodComponent.kitchenWastageInputs?.wastage ? foodComponent.kitchenWastageInputs?.wastage : '0'}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '16px', fontWeight: 600, color: theme.palette.neutral600 }}>
				{foodComponent.kitchenWastageInputs?.action ? foodComponent.kitchenWastageInputs?.action : 'No Action'}
			</TableCell>
			{/* leftOver Cost */}
			<TableCell sx={{ pl: 2, py: 2, fontSize: '16px', fontWeight: 600, color: theme.palette.neutral600 }}>
				{(foodComponent.kitchenWastageInputs?.wastage || 0) * (foodComponent?.cost || 0)}
			</TableCell>
		</TableRow>
	);
};

export default SectionTableRow;
