import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, concat } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const url = process.env.REACT_APP_API_URL!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const region = process.env.REACT_APP_AWS_REGION!;
const auth = {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	type: process.env.REACT_APP_AUTH_TYPE!,
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	apiKey: process.env.REACT_APP_API_KEY!
};

const retry = new RetryLink({
	delay: {
		initial: 100,
		max: Infinity
	},
	attempts: {
		max: Infinity,
		retryIf: (error, _operation) => !!error
	}
});
const http = new HttpLink({ uri: url });
const httpLink = concat(retry, http);

const link = ApolloLink.from([
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	createAuthLink({ url, region, auth }),
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
]);

export default new ApolloClient({
	link,
	cache: new InMemoryCache({
		typePolicies: {
			ComponentsQuantity: {
				keyFields: ['id', 'quantity'],
			},
			Food: {
				keyFields: ['id', 'kitchen', 'date']
			},
			FoodComponent: {
				keyFields: ['id', 'kitchen', 'date']
			},
			FoodComponentQualityStandard: {
				keyFields: ['id', 'name', 'description']
			},
			BaggingDispatchCity: {
				keyFields: ['id', 'name', 'total', 'modified', 'modifications']
			},
			ComponentShortage: {
				keyFields: ['id']
			},
			KitchenWastageInputs: {
				keyFields: ['preppedWeight', 'chilledWeight', 'wastage', 'action']
			},
			BaggingDispatch: {
				keyFields: ['id', 'session']
			},
			BaggingSession: {
				keyFields: ['id', 'kitchen', 'date', 'status', 'startTime', 'endTime', 'roomTemperature']
			},
			SessionInfo: {
				keyFields: ['kitchen', 'date']
			},
			Ingredient: {
				keyFields: ['id', 'quantity']
			},
			Complaint: {
				keyFields: ['id', 'kitchen', 'date']
			},
			Gift: {
				keyFields: ['id', 'kitchen', 'date']
			},
			QualityMetric: {
				keyFields: ['id', 'kitchen', 'day', 'shift']
			},
			QualityMetricFood: {
				keyFields: ['id', 'cacheKey']
			},
			QualityMetricsFoodComponent: {
				keyFields: ['id', 'cacheKey']
			}
		}
	})
});
