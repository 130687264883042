import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import EmptyMessage from '../../../../components/EmptyMessage';
import { FoodComponent } from '../../../../libs';
import SectionTable from '../SectionTable';

interface SectionPageProps {
	foodComponents: FoodComponent[];
}

const SectionPage = ({ foodComponents }: SectionPageProps) => (
	<Stack direction="column" sx={{ mt: '10px', mb: '5px', width: '100%' }}>
		<Box sx={{ width: '100%' }}>
			{foodComponents && foodComponents.length > 0 ? (
				<SectionTable foodComponents={foodComponents && foodComponents.length > 0 ? foodComponents : []} />
			) : (
				<EmptyMessage label={'no data'} style={{ margin: 0 }} />
			)}
		</Box>
	</Stack>
);

export default SectionPage;
