import { useState } from 'react';

import { Box, Typography } from '@mui/material';

import { caloTheme } from '../../../assets/themes/calo';
import Image from '../../../components/Image';
import ImageModal from '../../../components/ImageModal';
import { SingleFoodComponent } from '../../../libs';

interface FinalProductProps {
	selectedFoodComponent: SingleFoodComponent;
}

const FinalProduct = ({ selectedFoodComponent }: FinalProductProps) => {
	const [isModalOpened, setIsModalOpened] = useState(false);

	return (
		<Box sx={{ border: 'none', maxWidth: '120px', mt: 4 }}>
			<Typography sx={{ ml: '2px', fontFamily: caloTheme.typography.fontFamily, fontSize: '23px' }}>Final Product:</Typography>

			<Image
				url={`${process.env.REACT_APP_BUCKET_URL}/food-component/${selectedFoodComponent.id}/square@1x.jpg`}
				alt={selectedFoodComponent.name.en}
				width={240}
				height={240}
				onClick={() => setIsModalOpened(true)}
				style={{ marginLeft: '120px' }}
			/>
			<ImageModal
				isOpened={isModalOpened}
				handleClose={() => setIsModalOpened(false)}
				title={'Final product:'}
				url={`${process.env.REACT_APP_BUCKET_URL}/food-component/${selectedFoodComponent.id}/square@3x.jpg`}
				width={550}
			/>
		</Box>
	);
};

export default FinalProduct;
