import { format } from 'date-fns/fp';
import ExcelJS, { Column, Style } from 'exceljs';
import { saveAs } from 'file-saver';
import { useCallback } from 'react';

import { Icon } from '@iconify/react';
import Button from '@mui/material/Button';

import { caloTheme } from '../../../assets/themes/calo';
import { Delivery, DriverDispatch, DriverDispatchType } from '../../../libs';
import { formatDeliveryTime } from '../../../libs/utils/helperFunctions';

const border: Partial<Style> = {
	border: {
		top: { style: 'thin', color: { argb: 'D3D3D3' } },
		left: { style: 'thin', color: { argb: 'D3D3D3' } },
		bottom: { style: 'thin', color: { argb: 'D3D3D3' } },
		right: { style: 'thin', color: { argb: 'D3D3D3' } }
	}
};

const columnsCaloDriver: Array<Partial<Column>> = [
	{ header: 'Driver Name', width: 30, key: 'driverName', style: border },
	{ header: 'CALO Bags', width: 10, key: 'caloBags', style: border },
	{ header: 'Van Temperature', width: 20, key: 'vanTemperature', style: border },
	{ header: 'Departure Time', width: 20, key: 'departureTime', style: border }
];

const columnsOtherDriver: Array<Partial<Column>> = [
	{ header: 'Customer', width: 30, key: 'customer', style: border },
	{ header: 'Invoice Number', width: 20, key: 'invoiceNumber', style: border },
	{ header: 'Pick Up Time', width: 20, key: 'pickupTime', style: border },
	{ header: 'Custom Delivery Time', width: 20, key: 'customDeliveryTime', style: border }
];

interface ExportProps {
	deliveries: Delivery[];
	selectedDispatchType: DriverDispatchType;
	isDownloadDisabled: boolean;
	drivers: DriverDispatch[];
	kitchen: string;
	shift: string;
	date: string;
}

const Export = ({ drivers, isDownloadDisabled, kitchen, shift, deliveries, date, selectedDispatchType }: ExportProps) => {
	const onExport = useCallback(async () => {
		if (
			(selectedDispatchType === DriverDispatchType.caloDrivers && !drivers) ||
			(selectedDispatchType === DriverDispatchType.others && !deliveries)
		) {
			return;
		}
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(`Drivers ${kitchen}-${shift}-${date}`, {
			pageSetup: { fitToPage: true, orientation: 'portrait' }
		});
		worksheet.getRow(1).values =
			selectedDispatchType === DriverDispatchType.caloDrivers
				? ['Driver Name', 'CALO Bags', 'Van Temperature', 'Departure Time']
				: ['Customer', 'Invoice Number', 'Pickup Time', 'Custom Delivery Time'];
		worksheet.columns = selectedDispatchType === DriverDispatchType.caloDrivers ? columnsCaloDriver : columnsOtherDriver;
		if (selectedDispatchType === DriverDispatchType.caloDrivers) {
			for (const row of drivers) {
				worksheet.addRow(
					{
						driverName: row.driver.driverName,
						caloBags: row.caloBags,
						vanTemperature: row.vanTemperature,
						departureTime: row.departureTime ? format('HH:mm a')(new Date(row.departureTime)) : ''
					},
					''
				);
			}
		} else {
			for (const row of deliveries) {
				worksheet.addRow(
					{
						customer: row.name || '--',
						invoiceNumber: row.shortId || '--',
						pickupTime: row.pickupTime ? format('hh:mm a')(new Date(row.pickupTime)) : '--',
						customDeliveryTime: formatDeliveryTime(row.customDeliveryTime)
					},
					''
				);
			}
		}
		const buffer = await workbook.xlsx.writeBuffer();
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';
		const blob = new Blob([buffer], { type: fileType });
		saveAs(blob, `Drivers ${kitchen}-${shift}-${date}` + fileExtension);
	}, [drivers, deliveries, shift, kitchen, selectedDispatchType]);

	return (
		<>
			<Button
				id="export-button"
				onClick={onExport}
				disabled={isDownloadDisabled}
				variant="outlined"
				sx={{
					textTransform: 'none',
					fontWeight: 600,
					fontSize: '19px',
					mr: 4,
					color: caloTheme.palette.neutral900,
					borderColor: caloTheme.palette.neutral900,
					borderRadius: '8px',
					width: '140px',
					height: '50px',
					margin: 'auto'
				}}
				startIcon={<Icon icon="material-symbols:download" width={24} />}
			>
				Download
			</Button>
		</>
	);
};
export default Export;
