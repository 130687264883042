
    import { gql } from '@apollo/client';
    import { BAGGING_DISPATCH_FIELDS, BAGGING_SESSION_FIELDS, CHEF_FIELDS, COMPLAINT_FIELDS, COMPONENT_BATCH_FIELDS, DELIVERY_TICKET_FIELDS, DRIVER_DISPATCH_FIELDS, FOOD_COMPONENT_FIELDS, FOOD_FIELDS, SHOPPING_MENU_FIELDS } from './fragments';
    
      export const SUBSCRIBE_TO_BAGGING_DISPATCH_SUBSCRIPTION = gql`
        ${BAGGING_DISPATCH_FIELDS}
        subscription SubscribeToBaggingDispatch  {
          subscribeToBaggingDispatch {
            ...BaggingDispatchFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_BAGGING_SESSION_SUBSCRIPTION = gql`
        ${BAGGING_SESSION_FIELDS}
        subscription SubscribeToBaggingSession  {
          subscribeToBaggingSession {
            ...BaggingSessionFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE_SUBSCRIPTION = gql`
        ${FOOD_COMPONENT_FIELDS}
        subscription SubscribeToBatchCreateComponentShortage  {
          subscribeToBatchCreateComponentShortage {
            ...FoodComponentFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_BATCH_UPDATE_FOODS_PORTIONING_STATUS_SUBSCRIPTION = gql`
        ${FOOD_FIELDS}
        subscription SubscribeToBatchUpdateFoodsPortioningStatus  {
          subscribeToBatchUpdateFoodsPortioningStatus {
            ...FoodFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_CHEF_CHANGES_SUBSCRIPTION = gql`
        ${CHEF_FIELDS}
        subscription SubscribeToChefChanges  {
          subscribeToChefChanges {
            ...ChefFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_COMPONENT_BATCH_CHANGES_SUBSCRIPTION = gql`
        ${COMPONENT_BATCH_FIELDS}
        subscription SubscribeToComponentBatchChanges  {
          subscribeToComponentBatchChanges {
            ...ComponentBatchFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_DELIVERY_TICKET_CHANGES_SUBSCRIPTION = gql`
        ${DELIVERY_TICKET_FIELDS}
        subscription SubscribeToDeliveryTicketChanges  {
          subscribeToDeliveryTicketChanges {
            ...DeliveryTicketFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_RESOLVE_COMPLAINT_SUBSCRIPTION = gql`
        ${COMPLAINT_FIELDS}
        subscription SubscribeToResolveComplaint  {
          subscribeToResolveComplaint {
            ...ComplaintFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_UPDATE_DRIVER_DISPATCH_SUBSCRIPTION = gql`
        ${DRIVER_DISPATCH_FIELDS}
        subscription SubscribeToUpdateDriverDispatch  {
          subscribeToUpdateDriverDispatch {
            ...DriverDispatchFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_UPDATE_FOOD_COMPONENT_STATUS_SUBSCRIPTION = gql`
        ${FOOD_COMPONENT_FIELDS}
        subscription SubscribeToUpdateFoodComponentStatus  {
          subscribeToUpdateFoodComponentStatus {
            ...FoodComponentFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_UPDATE_FOOD_PORTIONING_SUBSCRIPTION = gql`
        ${FOOD_FIELDS}
        subscription SubscribeToUpdateFoodPortioning  {
          subscribeToUpdateFoodPortioning {
            ...FoodFields
          }
        }
      `;
    

      export const SUBSCRIBE_TO_UPDATE_SHOPPING_MENU_SUBSCRIPTION = gql`
        ${SHOPPING_MENU_FIELDS}
        subscription SubscribeToUpdateShoppingMenu  {
          subscribeToUpdateShoppingMenu {
            ...ShoppingMenuFields
          }
        }
      `;
    
  