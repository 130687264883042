import { Box, Divider } from '@mui/material';
import { useState } from 'react';
import { Kitchen, LeftoverMetricsViewTypes, Session } from '../../libs';
import ComponentsView from './ComponentsView';
import MealsView from './MealsView';

interface LeftoverMetricsProps {
	kitchen: Kitchen;
	date: string;
	areas?: string[];
	shift: Session;
}

const LeftoverMetrics = ({ kitchen, date, areas, shift }: LeftoverMetricsProps) => {
	const [selectedView, setSelectedView] = useState(LeftoverMetricsViewTypes.meals);
	return (
		<Box sx={{ width: '95%', margin: 'auto' }}>
			<Divider sx={{ height: '8px', marginBottom: '20px' }} />
			{selectedView === LeftoverMetricsViewTypes.meals && (
				<MealsView
					selectedView={selectedView}
					setSelectedView={setSelectedView}
					date={date}
					areas={areas ?? []}
					shift={shift}
					kitchen={kitchen}
				/>
			)}
			{selectedView === LeftoverMetricsViewTypes.components && (
				<ComponentsView selectedView={selectedView} setSelectedView={setSelectedView} date={date} kitchen={kitchen} />
			)}
		</Box>
	);
};

export default LeftoverMetrics;
