import FilterIcon from "@mui/icons-material/FilterAlt";
import { Button, Drawer } from "@mui/material";
import React, { useState } from "react";
import { PreDispatchCheckFilters, Session } from "../../../libs";
import FilterItems from "../FilterItems";

interface FilterButtonProps {
  areas: string[],
  shift: Session;
  filters: PreDispatchCheckFilters;
  setFilters: React.Dispatch<React.SetStateAction<PreDispatchCheckFilters>>;
}

export default function FilterButton({
  areas,
  shift,
  filters,
  setFilters
}: FilterButtonProps) {
  const [isFilterOpened, setIsFilterOpened] = useState(false)

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<FilterIcon />}
        onClick={() => setIsFilterOpened(true)}
        sx={{ py: 1 }}
      >
        Filters
      </Button>
      <Drawer
        anchor={'right'} open={isFilterOpened} onClose={() => setIsFilterOpened(false)}
      >
        <FilterItems
          areas={areas}
          shift={shift}
          filters={filters}
          setFilters={setFilters}
          toggleFilterDrawer={(toogleFilter) => setIsFilterOpened(toogleFilter)}
        ></FilterItems>
      </Drawer>
    </>
  )
}
