import { orderBy } from 'lodash-es';

import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../assets/themes/calo';
import { BaggingDispatch, PreDispatchCheckFilters } from '../../../libs';
import SectionTableRow from '../SectionTableRow';

interface SectionTableProps {
	dispatches: BaggingDispatch[];
	filters: PreDispatchCheckFilters
}

const SectionTable = ({
	dispatches,
	filters
}: SectionTableProps) => {
	const theme = useTheme();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: `${dispatches.length > 0 ? theme.palette.grey[100] : theme.palette.neutral100}`,
			color: theme.palette.common.black,
			border: 'none'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
			fontWeight: 600
		}
	}));

	return (
		<Table stickyHeader>
			<TableHead>
				<TableRow>
					<StyledTableCell sx={{ p: 1, pl: 2, py: 2, fontSize: '16px', width: '300px' }}>Meal</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '20px', textAlign: 'center' }}>Size</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '150px', textAlign: 'center' }}>Container Name</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '30px', textAlign: 'center' }}>Total</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '30px', textAlign: 'center' }}>Normal</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '30px', textAlign: 'center' }}>Modified</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '150px', textAlign: 'center' }}>Leftovers</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{dispatches.length > 0 ? (
					orderBy(dispatches, ['foodName.en', 'foodSize'], ['asc', 'desc']).map((dispatch) => (
						<SectionTableRow
							key={dispatch.id}
							dispatch={dispatch}
							filters={filters}
						/>
					))
				) : (
					<TableRow>
						<TableCell
							colSpan={9}
							sx={{
								minWidth: 150,
								p: 1,
								fontFamily: caloTheme.typography.fontFamily,
								fontSize: '28px',
								textTransform: 'uppercase',
								color: theme.palette.neutral600,
								textAlign: 'center',
								border: 'none'
							}}
						>
							No meals
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};

export default SectionTable;
