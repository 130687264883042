import { keyBy, uniqBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';
import { Dictionary } from '@calo/types';

import { FCStatus, FoodComponent, ListFoodComponentByRangeQuery } from '../libs';
import {
	GraphQLClient,
	LIST_FOOD_COMPONENT_BY_RANGE_QUERY,
	SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE_SUBSCRIPTION,
	SUBSCRIBE_TO_UPDATE_FOOD_COMPONENT_STATUS_SUBSCRIPTION
} from '../libs/graphQL';

interface UseListComponentByRangeQueryProps {
	kitchen: string;
	startDate: string;
	endDate: string;
	limit?: number;
	next?: string;
	filters?: Dictionary<boolean>;
	onCompleted?: (data: ListFoodComponentByRangeQuery) => void;
}

const useListComponentByRangeQuery = ({
	kitchen,
	startDate,
	endDate,
	limit,
	next,
	filters,
	onCompleted
}: UseListComponentByRangeQueryProps) => {
	const [foodComponents, setFoodComponents] = useState<FoodComponent[]>([]);
	const [newNext, setNewNext] = useState<string | undefined>(next);
	const { loading, refetch, fetchMore, subscribeToMore } = useQuery(LIST_FOOD_COMPONENT_BY_RANGE_QUERY, {
		variables: { kitchen, startDate, endDate, limit, next, filters },
		onCompleted: (data) => {
			if (data?.listFoodComponentByRange?.components) {
				setFoodComponents(data.listFoodComponentByRange.components);
			}
			if (data?.listFoodComponentByRange?.next) {
				setNewNext(data.listFoodComponentByRange.next);
			} else {
				setNewNext(undefined);
			}
			if (onCompleted) {
				onCompleted(data);
			}
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite'
	});

	useSubscription(SUBSCRIBE_TO_UPDATE_FOOD_COMPONENT_STATUS_SUBSCRIPTION);
	useSubscription(SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE_SUBSCRIPTION);

	const handleFetchMore = () => {
		if (newNext) {
			fetchMore({
				variables: { kitchen, startDate, endDate, limit, next: newNext, filters },
				updateQuery: (prev: any, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					setNewNext(fetchMoreResult?.listFoodComponentByRange?.next || undefined);
					const prevComponents = Array.isArray(prev?.listFoodComponentByRange?.components)
						? prev.listFoodComponentByRange.components
						: [];
					const moreComponents = Array.isArray(fetchMoreResult?.listFoodComponentByRange?.components)
						? fetchMoreResult.listFoodComponentByRange.components
						: [];
					const newFoodComponents = uniqBy([...prevComponents, ...moreComponents], 'id');

					return {
						...prev,
						listFoodComponentByRange: {
							...prev.listFoodComponentByRange,
							components: newFoodComponents,
							next: fetchMoreResult?.listFoodComponentByRange?.next
						}
					};
				}
			});
		}
	};

	const subscribeToMoreComponents = () =>
		subscribeToMore({
			document: SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE_SUBSCRIPTION,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscribeToBatchCreateComponentShortage = subscriptionData.data.subscribeToBatchCreateComponentShortage;
				const keyedById: Dictionary<FoodComponent> = keyBy(subscribeToBatchCreateComponentShortage, 'id');
				const prevComponents: FoodComponent[] = Array.isArray(prev?.listFoodComponentByRange?.components)
					? prev.listFoodComponentByRange.components
					: [];
				const updatedComponents: FoodComponent[] = prevComponents.map((c) => ({
					...c,
					shortages: keyedById[c.id] ? keyedById[c.id].shortages : c.shortages
				}));
				for (const [key, value] of Object.entries(keyedById)) {
					if (!updatedComponents.some((c) => c.id === key)) {
						updatedComponents.push(value);
					}
				}

				return {
					...prev,
					listFoodComponentByRange: {
						...prev.listFoodComponentByRange,
						components: [...updatedComponents],
						next: prev?.listFoodComponentByRange?.next
					}
				};
			}
		});

	useEffect(() => {
		subscribeToMoreComponents();
	}, []);

	const clearData = () => {
		setFoodComponents([]);
		setNewNext(undefined);
		GraphQLClient.clearStore();
	};

	const getNumberOfPendingShortages = () => {
		let numberOfPendingShortages = 0;
		for (const foodComponent of foodComponents) {
			numberOfPendingShortages +=
				foodComponent.shortages?.filter((s) => s.cookingStations?.some((c) => c.status === FCStatus.pending)).length ?? 0;
		}
		return numberOfPendingShortages;
	};

	return {
		componentsLoading: loading,
		foodComponents,
		refetchComponents: refetch,
		hasNext: Boolean(newNext),
		next: newNext,
		handleFetchMoreComponents: handleFetchMore,
		clearComponentsData: clearData,
		numberOfPendingShortages: getNumberOfPendingShortages()
	};
};

export default useListComponentByRangeQuery;
