import { FoodType } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, Chip, FormControl, FormControlLabel, IconButton, InputLabel, ListItemText, MenuItem, Radio, Select, Stack, Typography } from "@mui/material";
import { startCase } from 'lodash-es';
import { useState } from 'react';
import { caloTheme } from "../../../assets/themes/calo";
import { PreDispatchCheckFilters, Session, Shift } from '../../../libs';

interface FilterItemsProps {
  areas: string[],
  shift: Session;
  toggleFilterDrawer: (toggle: boolean) => void
  filters: PreDispatchCheckFilters;
  setFilters: React.Dispatch<React.SetStateAction<PreDispatchCheckFilters>>;
}

export default function FilterItems({ areas, shift, toggleFilterDrawer, filters, setFilters }: FilterItemsProps) {
  const [selectedShift, setSelectedShift] = useState<string>(filters.shift ?? Session.all)
  const [selectedMealTypes, setSelectedMealTypes] = useState<FoodType[]>(filters.mealTypes ?? [])
  const [selectedCities, setSelectedCities] = useState<string[]>(filters.cities ?? [])
  const [onlySandwiches, setOnlySandwiches] = useState(filters.onlySandwiches)

  const onApplyFilters = () => {
    setFilters({
      ...filters,
      shift: selectedShift,
      mealTypes: selectedMealTypes,
      cities: selectedCities,
      onlySandwiches,
    })
    toggleFilterDrawer(false)
  }

  const onClearFilters = () => {
    setFilters({
      ...filters,
      shift,
      mealTypes: [],
      cities: [],
      onlySandwiches: false,
    });
    toggleFilterDrawer(false)
  }


  return (
    <Stack direction={'column'} justifyContent={'space-between'} sx={{ width: 350, height: '100%', position: 'relative', p: 3 }} role="presentation">
      <Stack direction={'column'} spacing={5}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
          <Typography sx={{ fontSize: '24px', fontWeight: 700, color: caloTheme.palette.text.primary }}>
            Filters
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => toggleFilterDrawer(false)}
            sx={{
              fontWeight: 600,
              color: 'black'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          direction={"column"}
          spacing={3.5}
        >
          <FormControl fullWidth>
            <InputLabel>Shift</InputLabel>
            <Select label='Shift' value={selectedShift} onChange={(event) => setSelectedShift(event.target.value)} renderValue={(shift) => {
              return startCase(shift)
            }}
            >
              {Object.keys(Shift).map((shift) => <MenuItem key={shift} value={shift}>
                <FormControlLabel value={shift} control={<Radio checked={selectedShift === shift} />} label={startCase(shift)} />
              </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>{selectedCities.length === 0 ? 'Select Cities types' : 'Cities'}</InputLabel>
            <Select
              label={selectedCities.length === 0 ? 'Select Cities types' : 'Cities'}
              value={selectedCities}
              multiple
              onChange={(e) => setSelectedCities(e.target.value as string[])}
              renderValue={(cities) => {
                return <Stack direction={'row'} flexWrap={'wrap'} gap={0.5} rowGap={1}>
                  {cities.map((city) => {
                    return <Chip
                      key={city}
                      label={startCase(city)}
                      onMouseDown={(event) => event.stopPropagation()}
                      onDelete={() => {
                        setSelectedCities(selectedCities.filter(((selectedCity) => city !== selectedCity)))
                      }}
                    />
                  })}
                </Stack>
              }}
            >
              {areas.map((area) => <MenuItem key={area} value={area}>
                <Checkbox checked={selectedCities.includes(area)} />
                <ListItemText primary={startCase(area)} />
              </MenuItem>)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>{selectedMealTypes.length === 0 ? 'Select Meal types' : 'Meal types'}</InputLabel>
            <Select
              label={selectedMealTypes.length === 0 ? 'Select Meal types' : 'Meal types'}
              value={selectedMealTypes}
              multiple
              onChange={(e) => setSelectedMealTypes(e.target.value as FoodType[])}
              renderValue={(mealTypes) => {
                return <Stack direction={'row'} flexWrap={'wrap'} gap={0.5} rowGap={1}>
                  {mealTypes.map((mealType) => {
                    return <Chip
                      key={mealType}
                      label={startCase(mealType)}
                      onMouseDown={(event) => event.stopPropagation()}
                      onDelete={() => {
                        setSelectedMealTypes(selectedMealTypes.filter(((selectedMealType) => selectedMealType !== mealType)))
                      }}
                    />
                  })}
                </Stack>
              }}
            >
              {Object.values(FoodType).map((typeValue) => (
                <MenuItem key={typeValue} value={typeValue}>
                  <Checkbox checked={selectedMealTypes.includes(typeValue)} />
                  <ListItemText primary={startCase(typeValue)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Only Sandwiches</InputLabel>
            <Select
              label='Only Sandwiches'
              placeholder='Select sandwitches'
              value={onlySandwiches ? 1 : 0}
              onChange={(e) => setOnlySandwiches(Boolean(e.target.value))}
              renderValue={(val) => {
                return <>{val ? 'Yes' : 'No'}</>
              }}
            >
              <MenuItem value={1}>
                <FormControlLabel value={1} control={<Radio checked={onlySandwiches} />} label="Yes" />
              </MenuItem>
              <MenuItem value={0}>
                <FormControlLabel value={0} control={<Radio checked={!onlySandwiches} />} label="No" />
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2} width={'100%'}>
        <Button
          variant="outlined"
          sx={{ width: '50%' }}
          onClick={onClearFilters}
        >
          clear filter
        </Button>
        <Button variant="contained" sx={{ color: caloTheme.palette.white, width: '50%' }} onClick={onApplyFilters}>
          apply
        </Button>
      </Stack>
    </Stack>
  )
}
