import { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router';

import { KDSPermission } from '@calo/dashboard-types';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { CognitoUser, Kitchen, RoutePaths } from '../../libs';
import { useDocumentMedia } from '../../libs/utils/document';
import { getDate, getMaxDate, getMinDate, setKitchenToLocalStorage } from '../../libs/utils/helperFunctions';
import DatePicker from '../DatePicker';

import BarChartIcon from '@mui/icons-material/BarChart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PageviewIcon from '@mui/icons-material/Pageview';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TimelineIcon from '@mui/icons-material/Timeline';
import { compact } from 'lodash-es';
import { caloTheme } from '../../assets/themes/calo';
import Modal from '../Modal';
import GroupSection from './GroupSection';

const drawerWidth = 260;

export interface NotificationReq {
	lockTimeNotificationReq: { cxRequest: number; customerRequest: number };
	logisticsNotificationReq: { cxRequest: number; customerRequest: number };
	shortagesNotificationReq: number;
}
interface SidebarProps {
	isOpen: boolean;
	date: string;
	setDate: (date: string) => void;
	kitchen: string;
	setKitchen: (kitchen: Kitchen) => void;
	user: CognitoUser;
	notificationReq: NotificationReq;
	setIsOpen: (value: boolean) => void;
	signOut: ((data?: any | undefined) => void) | undefined;
}

const Sidebar = ({ signOut, isOpen, date, setDate, kitchen, setKitchen, user, notificationReq, setIsOpen }: SidebarProps) => {
	const { isMobile } = useDocumentMedia();
	const location = useLocation();
	const userPermissions = user?.permissions as KDSPermission[];
	const userKitchenList = user?.kitchen;
	const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);

	const handleKitchenChange = (event: SelectChangeEvent) => {
		setKitchenToLocalStorage(event.target.value);
		setKitchen(event.target.value as Kitchen);
	};

	useEffect(() => {
		if (userKitchenList) {
			setKitchenToLocalStorage(userKitchenList[0]);
			setKitchen(userKitchenList[0]);
		}
	}, []);

	const list = useMemo(() => {
		const allRoutes = [
			{
				title: 'Track',
				icon: <TimelineIcon />,
				routes: [
					{
						title: 'Components Check',
						route: RoutePaths.componentsCheck,
						permission: KDSPermission.VIEW_COMPONENTS_CHECK,
						isActive: matchPath(location.pathname, RoutePaths.componentsCheck)
					},
					{
						title: 'Assembly Check',
						route: RoutePaths.assemblyCheck,
						permission: KDSPermission.VIEW_ASSEMBLY_CHECK,
						isActive: matchPath(location.pathname, RoutePaths.assemblyCheck)
					},
					{
						title: 'Portioning Display',
						route: RoutePaths.portioningDisplay,
						permission: KDSPermission.VIEW_PORTIONING_DISPLAY,
						isActive: matchPath(location.pathname, RoutePaths.portioningDisplay)
					},
					{
						title: 'Quality Tracking',
						route: RoutePaths.qualityTracking,
						permission: KDSPermission.VIEW_QUALITY_TRACKING,
						isActive: matchPath(location.pathname, RoutePaths.qualityTracking)
					},
					{
						title: 'Wastage Tracking',
						route: RoutePaths.wastageTracking,
						permission: KDSPermission.VIEW_WASTAGE_TRACKING,
						isActive: matchPath(location.pathname, RoutePaths.wastageTracking)
					},
					{
						title: 'Shortage Reporting',
						route: RoutePaths.shortageReporting,
						permission: KDSPermission.VIEW_SHORTAGE_REPORTING,
						isActive: matchPath(location.pathname, RoutePaths.shortageReporting)
					}
				]
			},
			{
				title: 'Review',
				icon: <PageviewIcon />,
				routes: [
					{
						title: 'Recipe GuideBook',
						route: RoutePaths.recipeGuideBook,
						permission: KDSPermission.VIEW_RECIPE_GUIDEBOOK,
						isActive: matchPath(location.pathname, RoutePaths.recipeGuideBook)
					},
					{
						title: 'Meals Status',
						route: RoutePaths.mealsStatus,
						permission: KDSPermission.VIEW_MEALS_STATUS,
						isActive: matchPath(location.pathname, RoutePaths.mealsStatus)
					},
					{
						title: 'Pre-Dispatch Check',
						route: RoutePaths.preDispatchCheck,
						permission: KDSPermission.VIEW_PRE_DISPATCH_CHECK,
						isActive: matchPath(location.pathname, RoutePaths.preDispatchCheck)
					},
					{
						title: 'Shortage Display',
						route: RoutePaths.shortageDisplay,
						permission: KDSPermission.VIEW_SHORTAGE_DISPLAY,
						isActive: matchPath(location.pathname, RoutePaths.shortageDisplay)
					},
					{
						title: 'Driver Dispatch',
						route: RoutePaths.driverDispatch,
						permission: KDSPermission.VIEW_DRIVER_DISPATCH,
						isActive: matchPath(location.pathname, RoutePaths.driverDispatch)
					}
				]
			},
			{
				title: 'Requests',
				icon: <QuestionAnswerIcon />,
				routes: [
					{
						title: 'Kitchen',
						route: RoutePaths.lockTimeChanges,
						permission: KDSPermission.VIEW_LOCK_TIME_CHANGES,
						isActive: matchPath(location.pathname, RoutePaths.lockTimeChanges)
					},
					{
						title: 'Logistics',
						route: RoutePaths.logisticsChanges,
						permission: KDSPermission.VIEW_LOGISTICS_CHANGES,
						isActive: matchPath(location.pathname, RoutePaths.logisticsChanges)
					},
					{
						title: 'Special',
						route: RoutePaths.specialRequest,
						permission: KDSPermission.VIEW_SPECIAL_REQUEST,
						isActive: matchPath(location.pathname, RoutePaths.specialRequest)
					},
					{
						title: 'Files',
						route: RoutePaths.fileManagement,
						permission: KDSPermission.VIEW_FILE_MANAGEMENT,
						isActive: matchPath(location.pathname, RoutePaths.fileManagement)
					}
				]
			},
			{
				title: 'Metrics',
				icon: <BarChartIcon />,
				routes: [
					{
						title: 'Quality',
						route: RoutePaths.qualityMetrics,
						permission: KDSPermission.VIEW_QUALITY_METRICS,
						isActive: matchPath(location.pathname, RoutePaths.qualityMetrics)
					},
					{
						title: 'Productivity',
						route: RoutePaths.productivityMetrics,
						permission: KDSPermission.VIEW_PRODUCTIVITY_METRICS,
						isActive: matchPath(location.pathname, RoutePaths.productivityMetrics)
					},
					{
						title: 'Complaints',
						route: RoutePaths.customerComplaints,
						permission: KDSPermission.VIEW_CUSTOMER_COMPLAINTS,
						isActive: matchPath(location.pathname, RoutePaths.customerComplaints)
					},
					{
						title: 'Leftover Metrics',
						route: RoutePaths.leftoverMetrics,
						permission: KDSPermission.VIEW_LEFTOVER_METRICS,
						isActive: matchPath(location.pathname, RoutePaths.leftoverMetrics)
					}
				]
			}
		];

		return compact(
			allRoutes.map((route) => {
				route.routes = route.routes.filter((r) => userPermissions.includes(r.permission));
				return route.routes.length > 0 ? route : null;
			})
		);
	}, [location.pathname, userPermissions]);

	const DrawerHeader = styled('div')(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: 18,
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'center'
	}));

	return (
		<Drawer
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: drawerWidth,
					boxSizing: 'border-box'
				}
			}}
			variant={isMobile ? 'temporary' : 'persistent'}
			anchor="left"
			open={isOpen}
			onClose={() => setIsOpen(!isOpen)}
		>
			<DrawerHeader>
				<p>KDS</p>
			</DrawerHeader>
			<Divider />
			<List sx={{ width: '100%', maxWidth: 260, flexGrow: 1 }} component="nav">
				{list.map((route, index) => (
					<GroupSection key={index} notificationReq={notificationReq} routeGroup={route} />
				))}
			</List>
			<Divider sx={{ pb: 5 }} />

			<Box sx={{ p: 2, pb: 0 }}>
				<FormControl sx={{ width: '100%' }}>
					<Select value={kitchen} onChange={handleKitchenChange} inputProps={{ 'aria-label': 'Without label' }}>
						{userKitchenList.map((value) => (
							<MenuItem key={value} value={value}>
								{value} Kitchen
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box sx={{ p: 2, pb: 0 }}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						date={new Date(date)}
						minDate={getMinDate()}
						maxDate={getMaxDate()}
						label="Date"
						setDate={(date: Date | null) => {
							if (date) {
								setDate(getDate(date));
							}
						}}
						displayKeyboardView={true}
					/>
				</LocalizationProvider>
			</Box>
			<Button
				variant="outlined"
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					m: 2,
					p: 2,
					my: 4,
					cursor: 'pointer',
					color: caloTheme.palette.red,
					borderColor: caloTheme.palette.red,
					':hover': {
						borderColor: caloTheme.palette.red
					}
				}}
				onClick={() => setIsConfirmationOpened(true)}
			>
				<Typography sx={{ mr: 2 }}>LOG OUT</Typography>
				<ExitToAppIcon />
			</Button>

			<Modal
				loading={false}
				open={isConfirmationOpened}
				message={'Are you sure you want to log out?'}
				setOpen={(isOpened: boolean) => setIsConfirmationOpened(isOpened)}
				title={'Confirmation'}
				handleAccept={() => {
					if (signOut) {
						signOut();
					}
				}}
				handleClose={() => setIsConfirmationOpened(false)}
			/>
		</Drawer>
	);
};

export default Sidebar;
