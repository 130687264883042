import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, Stack } from '@mui/material';
import { startCase } from 'lodash-es';
import { useEffect, useState } from 'react';
import Chips from '../../../../components/Chips';
import DatePicker from '../../../../components/DatePicker';
import { CookingStationName } from '../../../../libs';
import { getDate } from '../../../../libs/utils/helperFunctions';
import FilterButtons from '../../Common/FilterButtons';
import FiltersDrawer from '../../Common/FiltersDrawer';
import { getDefaultDates } from '../../helpers';
import { ComponentsLeftoverMetricsFilters } from '../ComponentsView';

interface FilterItemsProps {
	isFilterOpened: boolean;
	setIsFilterOpened: (data: boolean) => void;
	filters: ComponentsLeftoverMetricsFilters;
	setFilters: (data: ComponentsLeftoverMetricsFilters) => void;
	date: string;
}

const FilterItems = ({ isFilterOpened, setIsFilterOpened, filters, setFilters, date }: FilterItemsProps) => {
	const [startDate, setStartDate] = useState<null | Date>(new Date(filters.startDate));
	const [endDate, setEndDate] = useState<null | Date>(new Date(filters.endDate));
	const [selectedCookingStations, setSelectedCookingStations] = useState<string[]>([]);

	useEffect(() => {
		if (!startDate || filters.startDate !== getDate(startDate)) {
			setStartDate(new Date(filters.startDate));
		}
		if (!endDate || filters.endDate !== getDate(endDate)) {
			setEndDate(new Date(filters.endDate));
		}
		if (!(filters.cookingStations.length !== selectedCookingStations.length) || !filters.cookingStations.every((cookingStation) => selectedCookingStations.includes(cookingStation))) {
			setSelectedCookingStations(filters.cookingStations)
		}
	}, [filters]);

	const handleApplyFilters = () => {
		setFilters({
			...filters,
			startDate: startDate ? getDate(startDate) : '',
			endDate: endDate ? getDate(endDate) : '',
			cookingStations: selectedCookingStations
		});
		setIsFilterOpened(false);
	};

	const handleClearFilters = () => {
		setFilters({
			...filters,
			...getDefaultDates(date),
			cookingStations: []
		});

		setStartDate(null);
		setEndDate(null);
		setSelectedCookingStations([]);

		setIsFilterOpened(false);
	};

	return (
		<FiltersDrawer isFilterOpened={isFilterOpened} setIsFilterOpened={setIsFilterOpened}>
			<Stack sx={{ height: '100%' }} direction={'column'} justifyContent={'space-between'}>
				<Stack direction={'column'} spacing={3.5}>
					<DatePicker
						date={startDate}
						setDate={(newDate) => newDate && setStartDate(newDate)}
						label={'Start Date'}
						showCalendarIcon={true}
					/>
					<DatePicker
						date={endDate}
						setDate={(newDate) => newDate && setEndDate(newDate)}
						label={'End Date'}
						showCalendarIcon={true}
					/>
					<FormControl fullWidth>
						<InputLabel>{selectedCookingStations.length ? 'Cooking Stations' : 'Select Cooking Stations'}</InputLabel>
						<Select
							multiple
							label={selectedCookingStations.length ? 'Cooking Stations' : 'Select Cooking Stations'}
							value={selectedCookingStations}
							onChange={(e) => setSelectedCookingStations(e.target.value as string[])}
							renderValue={(cookingStations) => {
								return (
									<Chips
										values={cookingStations}
										onDelete={(value) => {
											setSelectedCookingStations(
												selectedCookingStations.filter((selectedCookingStation) => selectedCookingStation !== value)
											);
										}}
									/>
								);
							}}
						>
							{Object.keys(CookingStationName).map((cookingStation) => (
								<MenuItem key={cookingStation} value={cookingStation}>
									<Checkbox checked={selectedCookingStations.includes(cookingStation)} />
									<ListItemText primary={startCase(cookingStation)} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Stack>
				<FilterButtons onApplyFilters={handleApplyFilters} onClearFilters={handleClearFilters} />
			</Stack>
		</FiltersDrawer>
	);
};
export default FilterItems;
