import { isEqual, uniqBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';

import { DeliveriesFiltersInput, Delivery, FileManagementType, ListDeliveriesInput } from '../libs';
import { GraphQLClient, LIST_DELIVERIES_QUERY } from '../libs/graphQL';

const useListDeliveryQuery = (
	filters: DeliveriesFiltersInput,
	initialFilters: DeliveriesFiltersInput,
	selectedDataType: FileManagementType,
	forCustomDeliveries?: boolean,
	limit = 30
) => {
	const [deliveries, setDeliveries] = useState<Delivery[]>([]);
	const [filteredDeliveries, setFilteredDeliveries] = useState<Delivery[]>([]);
	const [isFetchingMoreLoading, setIsFetchingMoreLoading] = useState(false);

	const [totalResult, setTotalResults] = useState<number>(0);
	const [page, setPage] = useState<string>('0');
	const [total, setTotal] = useState<number>(0);

	const mergeFetchedData = (fetchMoreResult: any) => {
		const prevDeliveries = Array.isArray(deliveries) ? deliveries : [];
		const newDeliveries = Array.isArray(fetchMoreResult?.listDeliveries?.data) ? fetchMoreResult.listDeliveries.data : [];
		const newLoadedDeliveries = uniqBy([...prevDeliveries, ...newDeliveries], 'id');
		setDeliveries(newLoadedDeliveries);
		setTotalResults(newLoadedDeliveries.length);
	};
	const {
		data: del,
		loading,
		fetchMore,
		refetch
	} = useQuery(LIST_DELIVERIES_QUERY, {
		variables: {
			query: {
				filters: {
					...filters,
					deliveryTime: filters.deliveryTime ? filters.deliveryTime : undefined,
					driverId: filters.driverId ? filters.driverId : undefined
				},
				limit,
				page: '0'
			}
		} as Partial<ListDeliveriesInput>,
		refetchWritePolicy: 'merge',
		partialRefetch: true,
		skip: forCustomDeliveries ? !filters.driverId : selectedDataType !== FileManagementType.customer,
		onCompleted: (data) => {
			if (data?.listDeliveries?.data) {
				const newData = data.listDeliveries.data;

				if (filters.name && (filters.userIds ?? []).length === 0) {
					setFilteredDeliveries([...newData]);
					setTotalResults([...newData].length);
				} else if (!filters.name && filters.userIds && filters.userIds.length > 0) {
					setDeliveries([...newData]);
					setTotalResults([...newData].length);
				} else {
					if (isEqual(initialFilters, filters)) {
						setDeliveries([...newData]);
						setTotalResults(newData.length);
						setFilteredDeliveries(filters.name ? [...newData] : []);
					} else {
						setDeliveries([...newData]);
						setTotalResults(newData.length);
						setFilteredDeliveries([]);
					}
				}
				setTotal(data.listDeliveries.total);
			}
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	const handleFetchMoreDeliveries = () => {
		const pageNum = +page + 1;
		setPage(pageNum.toString());
		setIsFetchingMoreLoading(true);

		if (totalResult < total) {
			fetchMore({
				variables: {
					query: {
						filters: { ...filters },
						limit,
						page: pageNum.toString()
					}
				} as Partial<ListDeliveriesInput>,
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					mergeFetchedData(fetchMoreResult);
					setIsFetchingMoreLoading(false);
				}
			});
		}
	};

	const clearData = () => {
		setPage('0');
		// eslint-disable-next-line unicorn/no-useless-undefined
		GraphQLClient.clearStore();
	};

	const handleRefetchData = () => {
		setPage('0');
		refetch();
		setDeliveries(del?.listDeliveries.data || []);
		setTotalResults(del?.listDeliveries.data.length || 30);
		setTotal(del?.listDeliveries.total);
	};

	useEffect(() => {
		if (!filters.deliveryTime && selectedDataType === FileManagementType.customer) {
			handleRefetchData();
		}
	}, [filters.deliveryTime, filters.driverId, selectedDataType]);

	return {
		deliveriesLoading: loading || isFetchingMoreLoading,
		filteredDeliveries: filteredDeliveries,
		deliveries: deliveries || [],
		hasNext: total > totalResult,
		handleFetchMoreDeliveries: handleFetchMoreDeliveries,
		clearDeliveriesData: clearData,
		refetchDeliveries: handleRefetchData
	};
};

export default useListDeliveryQuery;
