import { Stack } from '@mui/material';
import SummaryBox from '../../Common/SummaryBox';
import { LeftoverStats } from '../ComponentsView';

interface TopSectionProps {
	leftoverStats: LeftoverStats;
}

const TopSection = ({ leftoverStats }: TopSectionProps) => {
	return (
		<Stack sx={{ width: '100%' }} direction={'row'} spacing={'10px'} marginBottom={'35px'}>
			<SummaryBox topText="AMOUNT OF LEFTOVERS" bottomLeftText={leftoverStats.leftovers?.toString() ?? '-'} bottomRightText="g" />
			<SummaryBox
				topText="TOTAL COST OF LEFTOVERS"
				bottomLeftText={`${leftoverStats.currency ?? 'BHD'} ${leftoverStats.cost?.toString() ?? '-'}`}
			/>
		</Stack>
	);
};

export default TopSection;
