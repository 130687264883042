import { BaggingDispatch, PreDispatchCheckFilters } from '../../libs';
import { sumDispatchTotals } from './helperFunctions';

export const doesFoodMatchFilters = (dispatch: BaggingDispatch, filters: PreDispatchCheckFilters) => {
  return (
    dispatch.session === filters.shift &&
    checkIsSandwichFilter(dispatch, filters.onlySandwiches ?? false) &&
    doesIncludeSelectedMealType(dispatch, filters.mealTypes) &&
    doesIncludeSelectedCity(dispatch, filters.cities)
  );
};

export const checkIsSandwichFilter = (dispatch: BaggingDispatch, isSandwichFilter: boolean) => {
  return !isSandwichFilter || dispatch.isSandwich;
};

export const doesIncludeSelectedCity = (dispatch: BaggingDispatch, selectedCities?: string[]) => {
  const includedCities = [];

  for (const city of dispatch?.cities ?? []) {
    if (city.name && selectedCities?.includes(city.name)) {
      includedCities.push(city.name);
    }
  }

  const { totalCount } = sumDispatchTotals(dispatch, includedCities);

  return !selectedCities?.length || (includedCities.length && totalCount);
};

export const doesIncludeSelectedMealType = (dispatch: BaggingDispatch, selectedMealTypes?: string[]) => {
  return !selectedMealTypes?.length || dispatch.foodType?.some((type) => selectedMealTypes?.includes(type));
};
