import { Box, Typography, useTheme } from '@mui/material';

interface SummaryBoxProps {
	topText: string;
	bottomLeftText: string;
	bottomRightText?: string;
}

const SummaryBox = ({ topText, bottomLeftText, bottomRightText }: SummaryBoxProps) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: '100%',
				height: '100px',
				backgroundColor: theme.palette.grey[200],
				borderRadius: '10px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				paddingX: '10px'
			}}
		>
			<Typography sx={{ color: theme.palette.text.secondary }} fontWeight="500" fontSize="14px">
				{topText}
			</Typography>
			<Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
				<Typography sx={{ color: theme.palette.text.primary, marginRight: '5px' }} fontWeight="400" fontSize="24px">
					{bottomLeftText}
				</Typography>
				<Typography sx={{ color: theme.palette.text.primary, paddingTop: '6px' }} fontWeight="400" fontSize="14px">
					{bottomRightText}
				</Typography>
			</Box>
		</Box>
	);
};

export default SummaryBox;
