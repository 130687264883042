import { useMemo, useState } from 'react';

import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { caloTheme } from '../../../assets/themes/calo';
import EmptyMessage from '../../../components/EmptyMessage';
import { DeliveryTicketStatus, DeliveryTicketWithIndex } from '../../../libs';
import SwapCard from '../SwapCard';

interface TabPanelProps {
	deliveryTickets: DeliveryTicketWithIndex[];
	index: number;
	value: 0 | 1;
	loading: boolean;
	day?: string;
}

interface DeliveryTicketsObject {
	[userId: string]: DeliveryTicketWithIndex[];
}

const SwapTab = ({ deliveryTickets, value, index, loading, ...other }: TabPanelProps) => {
	const [isMutationRunning, setIsMutationRunning] = useState(false);
	const [openedCardId, setOpenedCardId] = useState<string | null>(null);
	const theme = useTheme();

	const modifiedDeliveryTicketsObject: DeliveryTicketsObject = useMemo(() => {
		const deliveryTicketsObject: DeliveryTicketsObject = {};

		for (const ticket of deliveryTickets) {
			const existingTicket = deliveryTicketsObject[ticket.data?.userId ?? ''];
			if (existingTicket) {
				existingTicket.unshift(ticket);
			} else {
				deliveryTicketsObject[ticket.data?.userId ?? ''] = [ticket];
			}
		}

		return deliveryTicketsObject;
	}, [deliveryTickets]);

	const changeOpenedCardIdHandler = (id: string | null) => setOpenedCardId(id);

	const deliveryTicketCards = () => {
		if (value === 0) {
			return deliveryTickets.map((deliveryTicket) => (
				<SwapCard
					key={deliveryTicket.id}
					{...deliveryTicket}
					tabValue={value}
					day={other.day!}
					index={deliveryTicket.index}
					isMutationRunning={isMutationRunning}
					setIsMutationRunning={setIsMutationRunning}
				/>
			));
		} else {
			return Object.values(modifiedDeliveryTicketsObject)
				.reverse()
				.map((ticketCollection, index) => {
					const collectionLength = ticketCollection.length;
					const borderColor =
						collectionLength === 1 && ticketCollection[0].status === DeliveryTicketStatus.rejected
							? theme.palette.red
							: theme.palette.primary500;

					return (
						<Card
							key={(ticketCollection[0].data?.userId ?? '') + index}
							sx={{
								minWidth: '100%',
								boxShadow: 0,
								maxHeight: 'auto',
								border: '2px solid' + theme.palette.neutral100,
								borderColor: borderColor,
								borderRadius: '8px',
								mb: 2
							}}
						>
							{ticketCollection.map((deliveryTicket, index) => (
								<SwapCard
									key={deliveryTicket.id}
									{...deliveryTicket}
									tabValue={value}
									numberOfTickets={collectionLength}
									isLastItem={index === collectionLength - 1}
									index={deliveryTicket.index}
									isCardOpened={openedCardId === deliveryTicket.id}
									isMutationRunning={isMutationRunning}
									setIsMutationRunning={setIsMutationRunning}
									setOpenedCardId={changeOpenedCardIdHandler}
								/>
							))}
						</Card>
					);
				});
		}
	};

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			sx={{ width: '100%' }}
			{...other}
		>
			{loading ? (
				<Box
					sx={{
						fontFamily: caloTheme.typography.fontFamily,
						fontSize: '28px',
						textTransform: 'uppercase',
						color: theme.palette.neutral600,
						mt: '10px'
					}}
				>
					Loading ...
				</Box>
			) : deliveryTickets.length > 0 && value === index ? (
				<Box sx={{ py: 2 }}> {deliveryTicketCards()}</Box>
			) : (
				<EmptyMessage label={'No Requests'} style={{ display: 'flex', justifyContent: 'center' }} />
			)}
		</Box>
	);
};

export default SwapTab;
