import { useQuery } from '@apollo/client';
import { Currency } from '@calo/types';
import { Backdrop, Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import EmptyMessage from '../../../components/EmptyMessage';
import { useListComponentByRangeQuery } from '../../../hooks';
import useExportFoodComponentsWastage from '../../../hooks/useExportFoodComponentsWastage';
import {
	Kitchen,
	LeftoverMetricsViewTypes,
	ListFoodComponentLeftoverStatsQuery,
	ListFoodComponentLeftoverStatsQueryVariables
} from '../../../libs';
import { LIST_FOOD_COMPONENT_LEFTOVER_STATS_QUERY } from '../../../libs/graphQL';
import { getCurrencyFromKitchen } from '../../../libs/utils';
import FilterSection from '../Common/FilterSection';
import { getDefaultDates } from '../helpers';
import FilterItems from './Filter/FilterDrawer';
import SectionPage from './SectionPage';
import TopSection from './TopSection';

interface ComponentsViewProps {
	selectedView: LeftoverMetricsViewTypes;
	setSelectedView: React.Dispatch<React.SetStateAction<LeftoverMetricsViewTypes>>;
	kitchen: Kitchen;
	date: string;
}

export interface ComponentsLeftoverMetricsFilters {
	startDate: string;
	endDate: string;
	cookingStations: string[];
}

export interface LeftoverStats {
	leftovers?: number;
	cost?: number;
	currency?: Currency;
}

const ComponentsView = ({ selectedView, setSelectedView, date, kitchen }: ComponentsViewProps) => {
	const [filters, setFilters] = useState<ComponentsLeftoverMetricsFilters>({
		cookingStations: [],
		...getDefaultDates(date)
	});
	const [isFilterOpened, setIsFilterOpened] = useState(false);

	const theme = useTheme();

	const { downloadFoodComponentWastage, loading: exportLoading } = useExportFoodComponentsWastage({ filters, kitchen });

	const appContext = useContext(AppContext);

	const { data: leftoverStatsResponse } = useQuery<
		ListFoodComponentLeftoverStatsQuery,
		ListFoodComponentLeftoverStatsQueryVariables
	>(LIST_FOOD_COMPONENT_LEFTOVER_STATS_QUERY, {
		variables: {
			kitchen,
			startDate: filters.startDate,
			endDate: filters.endDate
		}
	});

	const { hasNext, foodComponents, handleFetchMoreComponents, componentsLoading } = useListComponentByRangeQuery({
		kitchen,
		startDate: filters.startDate,
		endDate: filters.endDate,
		limit: 200
	});

	useEffect(() => {
		setFilters({
			...filters,
			...getDefaultDates(date)
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	const filteredFoodComponents = useMemo(() => {
		return foodComponents.filter((foodComponent) => {
			return (
				!filters.cookingStations.length ||
				foodComponent.cookingStations?.some((cookingStation) => filters.cookingStations.includes(cookingStation.name))
			);
		});
	}, [filters.cookingStations, foodComponents]);

	const leftOverStats = useMemo(() => {
		const defaultStats = {
			cost: 0,
			leftovers: 0,
			currency: getCurrencyFromKitchen(kitchen)
		};

		return (
			leftoverStatsResponse?.listFoodComponentLeftoverStats?.data?.reduce<LeftoverStats>((acc, { stations }) => {
				for (const station of stations ?? []) {
					if (!filters.cookingStations.length || filters.cookingStations.includes(station.name)) {
						acc.cost = (acc.cost ?? 0) + (station.totalLeftoverCost ?? 0);
						acc.leftovers = (acc.leftovers ?? 0) + (station.leftovers ?? 0);
					}
				}

				return acc;
			}, defaultStats) ?? defaultStats
		);
	}, [leftoverStatsResponse, filters.cookingStations, kitchen]);

	const handleDownload = () => {
		downloadFoodComponentWastage({
			variables: {
				kitchen,
				startDate: filters.startDate,
				endDate: filters.endDate
			}
		});
	};

	return (
		<InfiniteScroll
			dataLength={filteredFoodComponents.length}
			hasMore={hasNext}
			next={handleFetchMoreComponents}
			loader={
				hasNext ? (
					<Stack direction="column" alignItems="center">
						<CircularProgress sx={{ textAlign: 'center', m: 2 }} />
					</Stack>
				) : null
			}
		>
			<Box sx={{ width: '100%' }}>
				<TopSection leftoverStats={leftOverStats} />
				<FilterSection
					selectedView={selectedView}
					setSelectedView={setSelectedView}
					isLoading={exportLoading}
					handleDownload={handleDownload}
					openFilterDrawer={setIsFilterOpened}
					isFilterActive={false}
				/>
				<FilterItems
					setIsFilterOpened={setIsFilterOpened}
					filters={filters}
					setFilters={setFilters}
					isFilterOpened={isFilterOpened}
					date={date}
				/>
				<Stack direction="column" sx={{ mt: '25px', mb: '10px', width: '100%' }}>
					{foodComponents.length > 0 && !appContext.isOffline ? (
						componentsLoading ? (
							<Box
								sx={{
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									mt: '55px'
								}}
							>
								Loading ...
							</Box>
						) : (
							<SectionPage foodComponents={filteredFoodComponents} />
						)
					) : (
						<EmptyMessage label={'no data'} style={{ margin: 0, marginTop: '30px' }} />
					)}
				</Stack>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={componentsLoading && !appContext.isOffline}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Box>
		</InfiniteScroll>
	);
};

export default ComponentsView;
